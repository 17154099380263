import React from "react"
import GoogleMapReact from "google-map-react"
import mapStyles from "./mapStyles.json"
import styled from "styled-components"

const MapWrapper = styled.section`
  height: 460px;
  width: 100%;
  padding-bottom: 100px;
`

const MarkerStyle = styled.div`
  width: 25px;
  height: 25px;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
`

const settings = {
  center: {
    lat: 57.6984,
    lng: 11.94793,
  },
  zoom: 15,
}

const Map = ({ map }) => {
  return (
    <MapWrapper>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAUqxbMBbwj8ty1f6FDZM7ZEJg2lFtHMKk" }}
        defaultCenter={settings.center}
        defaultZoom={settings.zoom}
        yesIWantToUseGoogleMapApiInternals
        options={{
          styles: mapStyles,
        }}
      >
        <MarkerStyle lat={settings.center.lat} lng={settings.center.lng} />
      </GoogleMapReact>
    </MapWrapper>
  )
}

export default Map
